import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";

export default function Quote() {
  const [quote, setQuote] = useState("");
  const [author, setAuthor] = useState("");

  // Fetch a random quote from the API
  const fetchQuote = async () => {
    try {
      const response = await axios.get("https://api.quotable.io/random");
      setQuote(response.data.content);
      setAuthor(response.data.author);
    } catch (error) {
      console.error("Error fetching the quote", error);
      setQuote("Could not fetch quote at this time.");
      setAuthor("");
    }
  };

  // Fetch a quote when the component mounts
  useEffect(() => {
    fetchQuote();
  }, []);

  return (
    <div
      id="quote"
      className="relative min-h-screen flex items-center justify-center overflow-hidden bg-gradient-to-r from-indigo-500 to-indigo-600"
    >
      <div className="absolute top-0 left-0 w-full h-full opacity-30 z-10">
        {/* Floating Elements */}
        <div className="absolute top-1/4 left-1/4 w-32 h-32 bg-indigo-200 rounded-full opacity-50 animate-pulse"></div>
        <div className="absolute top-1/3 right-1/3 w-48 h-48 bg-indigo-300 rounded-full opacity-30 animate-bounce"></div>
        <div className="absolute bottom-1/4 left-1/2 w-64 h-64 bg-indigo-400 rounded-full opacity-40 animate-spin"></div>
      </div>
      <div className="relative z-20 bg-gray-100 p-8 rounded-lg shadow-lg max-w-md mx-auto text-center">
        <motion.p
          className="text-xl font-semibold text-gray-800"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          "{quote}"
        </motion.p>
        <motion.p
          className="mt-4 text-gray-600"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          - {author}
        </motion.p>
        <button
          onClick={fetchQuote}
          className="mt-6 bg-indigo-500 text-white py-2 px-4 rounded-full hover:bg-indigo-600 transition duration-300"
        >
          Get Another
        </button>
      </div>
    </div>
  );
}
