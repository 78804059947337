import React, { useState } from "react";
import logo from "./../assets/image/logo.svg";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigation = [
    { name: "Home", link: "#home" },
    { name: "Quote", link: "#quote" },
    { name: "Projects", link: "#projects" },
    { name: "Skills", link: "#skills" },
    { name: "Contact", link: "#contact" },
  ];

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <nav className="w-full bg-gradient-to-r from-indigo-500 to-indigo-600 flex justify-around items-center p-4 relative z-50">
      <div>
        <img src={logo} className="w-24" alt="Logo" />
      </div>
      <div className="hidden md:flex flex-row">
        {navigation.map((ele, index) => (
          <a
            key={index}
            className="m-3 hover:cursor-pointer text-white hover:text-indigo-100 transition-colors duration-300"
            href={ele.link}
          >
            {ele.name}
          </a>
        ))}
      </div>
      <div className="md:hidden flex items-center">
        <button
          onClick={toggleMenu}
          className="text-white hover:text-indigo-100 transition-colors duration-300"
        >
          {isOpen ? (
            <XMarkIcon className="h-8 w-8" />
          ) : (
            <Bars3Icon className="h-8 w-8" />
          )}
        </button>
      </div>
      <motion.div
        className={`fixed top-0 right-0 h-full w-2/3 bg-indigo-600 flex flex-col items-center justify-center transition-transform duration-300 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } z-50`}
        initial={{ opacity: 0 }}
        animate={{ opacity: isOpen ? 1 : 0 }}
        exit={{ opacity: 0 }}
      >
        <button
          onClick={toggleMenu}
          className="absolute top-4 right-4 text-white hover:text-indigo-100 transition-colors duration-300"
        >
          <XMarkIcon className="h-8 w-8" />
        </button>
        <div className="flex flex-col mt-16">
          {navigation.map((ele, index) => (
            <a
              key={index}
              className="py-4 text-white hover:text-indigo-100 transition-colors duration-300"
              href={ele.link}
              onClick={toggleMenu}
            >
              {ele.name}
            </a>
          ))}
        </div>
      </motion.div>
    </nav>
  );
}
