import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import Profile from "./../assets/image/Profile.png";

export default function Hero(props) {
  const heroTextRef = useRef(null);
  const profileRef = useRef(null);
  const downloadBtnRef = useRef(null);

  const resumeURL = props.resume;
  const profileURL = props.profile;

  useEffect(() => {
    // GSAP animations
    gsap.fromTo(
      heroTextRef.current,
      { opacity: 0, y: 30 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: "power3.out",
        stagger: 0.2,
      }
    );

    gsap.fromTo(
      profileRef.current,
      { opacity: 0, scale: 0.8 },
      {
        opacity: 1,
        scale: 1,
        duration: 1.2,
        ease: "power3.out",
        delay: 0.5,
      }
    );

    gsap.fromTo(
      downloadBtnRef.current,
      { opacity: 0, y: 20 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: "power3.out",
        delay: 1,
      }
    );
  }, []);

  return (
    <div
      id="home"
      className="relative bg-gray-100 min-h-screen flex items-center justify-center overflow-hidden"
    >
      <div className="w-10/12 md:w-8/12 mx-auto flex flex-col md:flex-row items-center px-6 md:px-12 relative z-10">
        {/* Text Section */}
        <div className="flex-1 text-center md:text-left">
          <h1
            ref={heroTextRef}
            className="text-4xl md:text-5xl font-bold text-gray-800"
          >
            Hi, I'm Amritanshu Prajapati
          </h1>
          <p ref={heroTextRef} className="mt-6 text-lg text-gray-600">
            A passionate web developer with experience in building responsive
            and scalable web applications.
          </p>
          <a
            ref={downloadBtnRef}
            href={resumeURL === "" ? "./resume.pdf" : resumeURL}
            download="Amritanshu_Prajapati_Resume.png"
            className="mt-8 inline-block bg-indigo-500 text-white py-3 px-6 rounded-full shadow-lg hover:bg-indigo-600 transition-transform transform hover:scale-105 duration-300"
          >
            Download CV
          </a>
        </div>

        {/* Profile Picture */}
        <div className="flex-1 mt-8 md:mt-0 flex justify-center">
          <img
            ref={profileRef}
            src={profileURL === "" ? Profile : profileURL}
            alt="Profile Pic"
            className="w-72 h-72 md:w-96 md:h-96 rounded-full object-cover shadow-2xl"
          />
        </div>
      </div>
    </div>
  );
}
