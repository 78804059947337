import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Quote from "./components/Quote";
import Contact from "./components/Contact";
import Skill from "./components/Skill";
import ScrollUpButton from "./components/ScrollUpButton";
import Project from "./components/Project";
import devData from "./assets/data/dev-data.json";

export default function App() {
  return (
    <div>
      <Navbar />
      <Hero resume={devData.resume} profile={devData.profile} />
      <Quote />
      <Skill skillProp={devData.skills} />
      <Project projectProp={devData.projects} />
      <Contact />
      <ScrollUpButton />
    </div>
  );
}
