import React, { useState } from "react";
import {
  FaInstagram,
  FaLinkedin,
  FaArrowRight,
  FaGithub,
} from "react-icons/fa";
import { CSSTransition } from "react-transition-group";
import { useForm, ValidationError } from "@formspree/react";
import "animate.css";

const ContactComponent = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [formSent, setFormSent] = useState(false);
  const [state, handleSubmit] = useForm("mjvdyleq");

  const handleNext = () => {
    const newErrors = {};
    if (step === 1 && !formData.name) newErrors.name = "Name is required.";
    if (step === 2 && !formData.email) newErrors.email = "Email is required.";
    if (step === 2 && formData.email && !/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email is invalid.";
    if (step === 3 && !formData.message)
      newErrors.message = "Message is required.";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setStep(step + 1);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit({
      data: formData,
    }).then(() => {
      if (state.succeeded) {
        setFormSent(true);
      }
    });
  };

  return (
    <div
      id="contact"
      className="flex flex-col justify-center items-center min-h-screen bg-indigo-600 text-white relative"
    >
      <div className="w-full max-w-lg p-8">
        <h2 className="text-3xl font-bold text-center mb-4">Get in touch</h2>

        {/* Social Media Links */}
        <div className="flex justify-center space-x-6 mb-6">
          <a
            href="https://www.instagram.com/amritanshu_prajapati"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-indigo-300"
          >
            <FaInstagram size={28} />
          </a>
          <a
            href="https://www.linkedin.com/in/amritanshu-prajapati-53765160/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-indigo-300"
          >
            <FaLinkedin size={28} />
          </a>
          <a
            href="https://github.com/Amrit-WEB"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-indigo-300"
          >
            <FaGithub size={28} />
          </a>
        </div>

        {/* Form or Success Message */}
        {!formSent ? (
          <form onSubmit={onSubmit} className="max-w-lg mx-auto">
            {step === 1 && (
              <div className="mb-4 relative">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                  className={`mt-1 block w-full px-3 py-2 pr-12 bg-indigo-500 border border-indigo-400 rounded-md shadow-sm placeholder-indigo-200 focus:outline-none focus:ring-indigo-400 focus:border-indigo-400`}
                />
                <button
                  type="button"
                  onClick={handleNext}
                  className="absolute inset-y-0 right-0 flex items-center px-4 bg-indigo-700 text-white rounded-r-md"
                >
                  <FaArrowRight />
                </button>
                {errors.name && (
                  <div className="absolute top-0 right-0 transform translate-y-full animate__animated animate__fadeIn animate__faster bg-red-500 text-white p-2 rounded">
                    {errors.name}
                  </div>
                )}
              </div>
            )}

            {step === 2 && (
              <div className="mb-4 relative">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  className={`mt-1 block w-full px-3 py-2 pr-12 bg-indigo-500 border border-indigo-400 rounded-md shadow-sm placeholder-indigo-200 focus:outline-none focus:ring-indigo-400 focus:border-indigo-400`}
                />
                <button
                  type="button"
                  onClick={handleNext}
                  className="absolute inset-y-0 right-0 flex items-center px-4 bg-indigo-700 text-white rounded-r-md"
                >
                  <FaArrowRight />
                </button>
                {errors.email && (
                  <div className="absolute top-0 right-0 transform translate-y-full animate__animated animate__fadeIn animate__faster bg-red-500 text-white p-2 rounded">
                    {errors.email}
                  </div>
                )}
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </div>
            )}

            {step === 3 && (
              <div className="mb-4 relative">
                <textarea
                  name="message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Message"
                  className={`mt-1 block w-full px-3 py-2 pr-12 bg-indigo-500 border border-indigo-400 rounded-md shadow-sm placeholder-indigo-200 focus:outline-none focus:ring-indigo-400 focus:border-indigo-400`}
                ></textarea>
                <button
                  type="submit"
                  className="absolute inset-y-0 right-0 flex items-center px-4 bg-indigo-800 text-white rounded-r-md"
                >
                  Send
                </button>
                {errors.message && (
                  <div className="absolute top-0 right-0 transform translate-y-full animate__animated animate__fadeIn animate__faster bg-red-500 text-white p-2 rounded">
                    {errors.message}
                  </div>
                )}
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
              </div>
            )}
          </form>
        ) : (
          <CSSTransition
            in={formSent}
            timeout={500}
            classNames="fade"
            unmountOnExit
          >
            <div className="text-center animate__animated animate__fadeIn animate__slow">
              <h3 className="text-xl font-bold">
                Thank you, I will reach you out soon!
              </h3>
            </div>
          </CSSTransition>
        )}
      </div>

      {/* Footer */}
      <footer className="bg-indigo-800 text-center py-4 w-full absolute bottom-0">
        <p className="text-sm">© 2024. Made with ❣️ by Amritanshu Prajapati</p>
      </footer>
    </div>
  );
};

export default ContactComponent;
