import React from "react";
import { motion } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SkillItem = ({ name, icon }) => (
  <motion.div
    className="flex flex-col items-center p-4 transition-transform duration-300 transform hover:scale-105"
    whileHover={{ scale: 1.1, rotate: 10 }}
  >
    <img src={icon} alt={name} className="h-12 w-12 mb-2" />
    <span className="text-gray-700">{name}</span>
  </motion.div>
);

export default function Skills(prop) {
  const skills = prop.skillProp;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6, // Show 6 slides at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div
      id="skills"
      className="p-8 bg-gray-100 min-h-svh flex items-center justify-center"
    >
      <div className="w-full max-w-screen-lg">
        <h2 className="text-3xl font-bold mb-8 text-center text-indigo-500">
          My Skills
        </h2>
        <Slider {...settings}>
          {skills.map((skill) => (
            <SkillItem key={skill.name} {...skill} />
          ))}
        </Slider>
      </div>
    </div>
  );
}
