{
  "resume": "https://www.dropbox.com/scl/fi/20vlq215l5jj5ddofy60m/Amritanshu-Resume-FullStack.pdf?rlkey=5edcc8sbchmz10r76quyq549s&st=h7o8ubcb&raw=1",
  "profile": "",
  "skills": [
    {
      "name": "HTML",
      "icon": "https://img.icons8.com/color/48/000000/html-5.png"
    },
    {
      "name": "CSS",
      "icon": "https://img.icons8.com/color/48/000000/css3.png"
    },
    {
      "name": "JavaScript",
      "icon": "https://img.icons8.com/color/48/000000/javascript.png"
    },
    {
      "name": "React",
      "icon": "https://img.icons8.com/color/48/000000/react-native.png"
    },
    {
      "name": "Tailwind CSS",
      "icon": "https://img.icons8.com/color/48/000000/tailwindcss.png"
    },
    {
      "name": "Bootstrap",
      "icon": "https://img.icons8.com/color-glass/48/bootstrap.png"
    },
    {
      "name": "Node.js",
      "icon": "https://img.icons8.com/color/48/000000/nodejs.png"
    },
    { "name": "Git", "icon": "https://img.icons8.com/color/48/000000/git.png" },
    {
      "name": "Java",
      "icon": "https://img.icons8.com/color/48/000000/java-coffee-cup-logo.png"
    },
    {
      "name": "Spring",
      "icon": "https://img.icons8.com/color/48/spring-logo.png"
    },
    {
      "name": "Spring Boot",
      "icon": "https://img.icons8.com/office/40/spring-logo.png"
    },
    {
      "name": "MongoDB",
      "icon": "https://img.icons8.com/color/48/000000/mongodb.png"
    },
    {
      "name": "MySQL",
      "icon": "https://img.icons8.com/color/48/000000/mysql-logo.png"
    },
    {
      "name": "PHP",
      "icon": "https://img.icons8.com/officel/80/php-logo.png"
    },
    {
      "name": "Postman",
      "icon": "https://img.icons8.com/dusk/64/postman-api.png"
    },
    {
      "name": "Redux",
      "icon": "https://img.icons8.com/color/48/000000/redux.png"
    },
    {
      "name": "RESTful API",
      "icon": "https://img.icons8.com/office/40/api.png"
    },
    {
      "name": "AWS",
      "icon": "https://img.icons8.com/color/48/amazon-web-services.png"
    },
    {
      "name": "Canva",
      "icon": "https://img.icons8.com/color/48/000000/canva.png"
    }
  ],
  "projects": [
    {
      "title": "BillSplitter",
      "description": "The BillSplitter app allows friends to split bills equally, requiring approval from one friend before adding a bill. Users can also settle bills, ensuring all expenses are resolved.",
      "techStack": [
        {
          "name": "Java",
          "icon": "https://img.icons8.com/color/48/000000/java-coffee-cup-logo.png"
        },
        {
          "name": "Spring Boot",
          "icon": "https://img.icons8.com/color/48/spring-logo.png"
        },
        {
          "name": "MySQL",
          "icon": "https://img.icons8.com/color/48/000000/mysql-logo.png"
        },
        {
          "name": "Bootstrap",
          "icon": "https://img.icons8.com/color-glass/48/bootstrap.png"
        },
        {
          "name": "Thymeleaf",
          "icon": "https://img.icons8.com/color/48/thymeleaf.png"
        }
      ],
      "projectImage": "https://www.dropbox.com/scl/fi/hel84wi00pn0xq20dxee9/billsplitter.png?rlkey=dymhvzaup41ld2j4z82armr66&st=7iczvkx5&raw=1",
      "liveLink": "https://billsplitter.onrender.com/",
      "gitLink": "https://github.com/Amrit-WEB/billsplitterG",
      "isClientProject": false,
      "date": "Jan 2024"
    },
    {
      "title": "Tourflix",
      "description": "TourFlix is a feature-rich web application built using Express.js, Pug, Node.js, CSS, and SASS. It provides users with the ability to explore and book tours, create their profiles, and enjoy a seamless booking experience with robust authentication and payment integration..",
      "techStack": [
        {
          "name": "NodeJS",
          "icon": "https://img.icons8.com/color/48/000000/nodejs.png"
        },
        {
          "name": "ExpressJS",
          "icon": "https://img.icons8.com/ios/50/express-js.png"
        },
        {
          "name": "PUG",
          "icon": "https://img.icons8.com/color/48/pug.png"
        },
        {
          "name": "SaSS",
          "icon": "https://img.icons8.com/color/48/sass.png"
        },
        {
          "name": "Stripe",
          "icon": "https://img.icons8.com/color/48/stripe.png"
        }
      ],
      "projectImage": "https://www.dropbox.com/scl/fi/pyrua2od5nhl5ov8wdhna/tourflix.png?rlkey=tuv2j41eg7a3m62e4xvekdil2&st=mf7jurjk&raw=1",
      "liveLink": "https://tourflix.onrender.com/login",
      "gitLink": "https://github.com/Amrit-WEB/tourflix",
      "isClientProject": false,
      "date": "Feb 2023"
    },
    {
      "title": "PixelSheild",
      "description": "PixelShield is an image steganography project using LSB algorithm. It securely embeds a hashed secret message in an image, requiring a cryptographic key for decryption to retrieve the original message.",
      "techStack": [
        {
          "name": "HTML",
          "icon": "https://img.icons8.com/color/48/000000/html-5.png"
        },
        {
          "name": "CSS",
          "icon": "https://img.icons8.com/color/48/000000/css3.png"
        },
        {
          "name": "JavaScript",
          "icon": "https://img.icons8.com/color/48/000000/javascript.png"
        },
        {
          "name": "Parcel",
          "icon": "https://img.icons8.com/stickers/50/open-parcel.png"
        }
      ],
      "projectImage": "https://www.dropbox.com/scl/fi/4zlnnz2l1txgxetss63bp/pixelsheild.png?rlkey=aewlvej4puvrcdtgaa2jcd4sw&st=ijy8efug&raw=1",
      "liveLink": "https://pixelsheild.netlify.app/",
      "gitLink": "https://github.com/Amrit-WEB/pixelsheild",
      "isClientProject": false,
      "date": "Oct 2023"
    },
    {
      "title": "Hitnum",
      "description": "HitNum is a fast-paced game where you must click all target numbers before a timer runs out. Click wrong numbers or run out of time, and you lose. Enjoy the challenge!",
      "techStack": [
        {
          "name": "HTML",
          "icon": "https://img.icons8.com/color/48/000000/html-5.png"
        },
        {
          "name": "CSS",
          "icon": "https://img.icons8.com/color/48/000000/css3.png"
        },
        {
          "name": "JavaScript",
          "icon": "https://img.icons8.com/color/48/000000/javascript.png"
        }
      ],
      "projectImage": "https://www.dropbox.com/scl/fi/8ysu5zgcinye7udyj2yie/hitnum.png?rlkey=nw0pm47ce5c252pzg9352jd18&st=ed4o5mx8&raw=1",
      "liveLink": "https://amrit-web.github.io/hitnum/",
      "gitLink": "https://github.com/Amrit-WEB/hitnum",
      "isClientProject": false,
      "date": "Aug 2023"
    },
    {
      "title": "After-Skool",
      "description": "The Afters-Skool project is a static page designed to provide routines and information for after-school activities, offering parents and students an organized overview of available programs.",
      "techStack": [
        {
          "name": "HTML",
          "icon": "https://img.icons8.com/color/48/000000/html-5.png"
        },
        {
          "name": "CSS",
          "icon": "https://img.icons8.com/color/48/000000/css3.png"
        },
        {
          "name": "JavaScript",
          "icon": "https://img.icons8.com/color/48/000000/javascript.png"
        }
      ],
      "projectImage": "https://www.dropbox.com/scl/fi/zhc0rwz00uozr1go4ztje/afterskool.png?rlkey=kxij82s63096mxdit4kvs6lcv&st=7hzewqaj&raw=1",
      "liveLink": "http://after-skool.in",
      "gitLink": "",
      "isClientProject": true,
      "date": "Sept 2022"
    },
    {
      "title": "Vacc-Slot",
      "description": "Vacc-Slot is a vanilla JavaScript web app using MVC architecture and Webpack. It fetches real-time vaccination slots via the CoWIN API. Includes user-friendly UI and scalable codebase.",
      "techStack": [
        {
          "name": "HTML",
          "icon": "https://img.icons8.com/color/48/000000/html-5.png"
        },
        {
          "name": "CSS",
          "icon": "https://img.icons8.com/color/48/000000/css3.png"
        },
        {
          "name": "JavaScript",
          "icon": "https://img.icons8.com/color/48/000000/javascript.png"
        },
        {
          "name": "Webpack",
          "icon": "https://img.icons8.com/color/48/000000/webpack.png"
        },
        {
          "name": "API",
          "icon": "https://img.icons8.com/color/48/api.png"
        }
      ],
      "projectImage": "https://www.dropbox.com/scl/fi/v2n53mzxguizc8j26burv/vaccslot.png?rlkey=cjjh2btxnfaqruc9q4fb79bul&st=248ak5ie&raw=1",
      "liveLink": "https://amrit-web.github.io/vaccine/dist/",
      "gitLink": "https://github.com/Amrit-WEB/vaccine",
      "isClientProject": false,
      "date": "May 2021"
    },
    {
      "title": "LNMI Result Management System",
      "description": "LNMI Result Management System is a PHP-based web app using MySQL for student result management. Admins manage results; students search results by roll number. A graduation project built with PHP.",
      "techStack": [
        {
          "name": "HTML",
          "icon": "https://img.icons8.com/color/48/000000/html-5.png"
        },
        {
          "name": "CSS",
          "icon": "https://img.icons8.com/color/48/000000/css3.png"
        },
        {
          "name": "JavaScript",
          "icon": "https://img.icons8.com/color/48/000000/javascript.png"
        },
        {
          "name": "PHP",
          "icon": "https://img.icons8.com/officel/80/php-logo.png"
        },
        {
          "name": "MySQL",
          "icon": "https://img.icons8.com/color/48/000000/mysql-logo.png"
        },
        {
          "name": "XAMPP",
          "icon": "https://static-00.iconduck.com/assets.00/xampp-icon-508x512-hsh5ht6u.png"
        }
      ],
      "projectImage": "https://www.dropbox.com/scl/fi/l1hkrvn7kpzthsr7n78h5/lnmi.png?rlkey=shpq4ux7qb2r7cl1cdchkak6l&st=n2w1vyxu&raw=1",
      "liveLink": "http://lnmiresult.infinityfreeapp.com/",
      "gitLink": "https://github.com/Amrit-WEB/lnmiresult",
      "isClientProject": false,
      "date": "Apr 2021"
    },
    {
      "title": "AmritAakash",
      "description": "Developed a weather app using the OpenWeather API, featuring real-time weather data, geolocation, city search, and a clean, user-friendly interface optimized for various devices.",
      "techStack": [
        {
          "name": "HTML",
          "icon": "https://img.icons8.com/color/48/000000/html-5.png"
        },
        {
          "name": "CSS",
          "icon": "https://img.icons8.com/color/48/000000/css3.png"
        },
        {
          "name": "JavaScript",
          "icon": "https://img.icons8.com/color/48/000000/javascript.png"
        },
        {
          "name": "Webpack",
          "icon": "https://img.icons8.com/color/48/000000/webpack.png"
        },
        {
          "name": "API",
          "icon": "https://img.icons8.com/color/48/api.png"
        }
      ],
      "projectImage": "https://www.dropbox.com/scl/fi/2xym1tbouyldj0son6are/amritaakash.png?rlkey=1838o1gra5to7ohzaj5ktvey1&st=gacsos1j&raw=1",
      "liveLink": "https://amrit-web.github.io/amritaakash/dist/",
      "gitLink": "https://github.com/Amrit-WEB/amritaakash",
      "isClientProject": false,
      "date": "May 2020"
    }
  ]
}
