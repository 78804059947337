import React, { useState, useEffect } from "react";
import {
  FaExternalLinkAlt,
  FaGithub,
  FaTimes,
  FaTag,
  FaChevronLeft,
  FaChevronRight,
  FaCalendar,
} from "react-icons/fa";

export default function Project(prop) {
  const [openProject, setOpenProject] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 3;

  const projects = prop.projectProp;

  const totalPages = Math.ceil(projects.length / projectsPerPage);

  const handleOpenDialog = (project) => {
    setOpenProject(project);
    document.body.style.overflow = "hidden"; // Prevent scrolling when dialog is open
  };

  const handleCloseDialog = () => {
    setOpenProject(null);
    document.body.style.overflow = "auto"; // Re-enable scrolling when dialog is closed
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Get the current projects to display
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = projects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  useEffect(() => {
    // Clean up overflow when component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div
      id="projects"
      className="p-8 bg-gray-100 min-h-screen flex flex-col items-center justify-center"
    >
      <div className="w-full max-w-screen-lg">
        <h2 className="text-3xl font-bold mb-8 text-center text-indigo-500">
          My Projects
        </h2>
        <div className="w-full flex flex-col justify-center items-center">
          <div className="w-full flex flex-wrap justify-center items-center transition-transform duration-500 ease-in-out">
            {currentProjects.map((project, index) => (
              <div
                key={index}
                className="max-w-md min-w-64 rounded-lg overflow-hidden shadow-lg bg-white m-4"
              >
                <img
                  className="w-full h-56 object-cover"
                  src={project.projectImage}
                  alt={`${project.title} project`}
                />
                <div className="p-4 flex justify-center">
                  <button
                    onClick={() => handleOpenDialog(project)}
                    className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded flex items-center"
                  >
                    <FaExternalLinkAlt className="mr-2" />
                    View
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination Controls */}
          <div className="flex justify-center mt-8 space-x-4">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className={`${
                currentPage === 1
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-indigo-600 hover:bg-indigo-700"
              } text-white font-bold py-2 px-4 rounded-l flex items-center`}
            >
              <FaChevronLeft className="mr-2" />
              Prev
            </button>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className={`${
                currentPage === totalPages
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-indigo-600 hover:bg-indigo-700"
              } text-white font-bold py-2 px-4 rounded-r flex items-center`}
            >
              Next
              <FaChevronRight className="ml-2" />
            </button>
          </div>

          {/* Dialog Box */}
          {openProject && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <div
                className="bg-white p-6 rounded-lg max-w-lg w-full relative transition-transform transform scale-100 duration-300 ease-in-out"
                style={{ animation: "fadeIn 0.3s ease-in-out" }}
              >
                <button
                  onClick={handleCloseDialog}
                  className="absolute top-2 right-2 text-red-600 hover:text-red-800 text-xl"
                >
                  <FaTimes />
                </button>
                <h2 className="text-2xl font-bold mb-4">{openProject.title}</h2>
                <div className="mr-4 mb-4 inline-flex items-center text-sm font-semibold bg-red-200 text-red-800 px-2 py-1 rounded">
                  <FaCalendar className="mr-1" /> {openProject.date}
                </div>
                {openProject.isClientProject && (
                  <div className="mb-4 inline-flex items-center text-sm font-semibold bg-yellow-200 text-yellow-800 px-2 py-1 rounded">
                    <FaTag className="mr-1" /> Client Project
                  </div>
                )}
                <p className="text-gray-700 mb-4">{openProject.description}</p>
                <h4 className="font-bold text-lg mb-2">Tech Stack:</h4>
                <div className="flex flex-wrap mb-4">
                  {openProject.techStack.map((tech, techIndex) => (
                    <div
                      key={techIndex}
                      className="mr-2 mb-2 flex items-center"
                    >
                      <img
                        src={tech.icon}
                        alt={tech.name}
                        className="inline-block w-6 h-6"
                      />
                      {/* <span className="ml-2 text-gray-700 text-sm">
                        {tech.name}
                      </span> */}
                    </div>
                  ))}
                </div>
                <div className="flex justify-end">
                  {openProject.liveLink && (
                    <a
                      href={openProject.liveLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded mr-2 flex items-center"
                    >
                      <FaExternalLinkAlt className="mr-2" />
                      Live
                    </a>
                  )}
                  {!openProject.isClientProject && openProject.gitLink && (
                    <a
                      href={openProject.gitLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-gray-800 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded flex items-center"
                    >
                      <FaGithub className="mr-2" />
                      Git
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
